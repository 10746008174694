import * as React from 'react';

const IconShortTimer: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      style={{
        width: '100%',
        maxWidth: '100%',
      }}
      className="prefix__block prefix__fill-current"
    >
      Clock icon (short time)
      <path
        fill="currentColor"
        d="M24.87 50.05a25 25 0 1125-25 25 25 0 01-25 25zm5.9-43.67a19.47 19.47 0 00-5.9-.91v19.59l5.91-18.68z"
      />
    </svg>
  );
};

export default IconShortTimer;
