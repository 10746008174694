import config from '../../config/config';
import api from '../api';

async function logout(): Promise<void> {
  await api.post<void>(`/logout`, {}, { baseURL: config.api.root });

  window.location.replace(config.api.root);
}

export default logout;
