import DataType from '../DataType.enum';
import { ITherapySessionAttributes } from './ITherapySessionAttributes';

export enum Role {
  Patient = 'patient',
  Administrator = 'administrator',
}

export interface ITherapySession {
  id: string | undefined;
  type: DataType.TherapySessions;
  attributes: ITherapySessionAttributes;
  meta: {
    roles: Role[];
  };
}
