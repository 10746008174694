const KEYS = {
  KBLeft: 'ArrowLeft',
  KBRight: 'ArrowRight',
  // Mini Bluetooth keyboard
  MKLeft: 'KeyA',
  MKCenter: 'KeyB',
  MKRight: 'KeyC',
};

export default KEYS;
