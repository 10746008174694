import React, { createContext, Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import config from '../config/config';
import createTherapySession from '../data/therapySessions/createTherapySession';
import updateTherapySession from '../data/therapySessions/updateTherapySession';
import SpeechResponse from '../models/SpeechResponse';
import { IPathItem } from '../models/therapySession/IPathItem';
import { ITherapySession } from '../models/therapySession/ITherapySession';
import { Role } from '../models/therapySession/ITherapySession';
import { uniqueObjectArrayByKey } from '../utils/uniqueObjectArrayByKey';

interface ITherapySessionContext {
  therapySession: ITherapySession | undefined;
  updatePath: (nextId: string, response?: SpeechResponse, experienceLevel?: number) => void;
  prevId: string;
  alarms: {
    hasAlarms: boolean;
    setHasAlarms: Dispatch<SetStateAction<boolean>>;
  };
  relaxationOnly: boolean;
  facilitatorSession: boolean;
}

function now(): string {
  return new Date().toISOString();
}

function missingProvider(): void {
  console.warn('TherapySession.Provider missing');
}

function isFacilitatorSession(therapySession: ITherapySession): boolean {
  return !!therapySession?.attributes.facilitator_id;
}

function isRelaxationOnly(therapySession: ITherapySession): boolean | null {
  if (!therapySession?.meta?.roles) {
    return null;
  }

  if (isFacilitatorSession(therapySession)) {
    return false;
  }

  return !therapySession?.meta?.roles?.find(role => [Role.Administrator, Role.Patient].includes(role));
}

export const TherapySessionContext = createContext<ITherapySessionContext>({
  therapySession: undefined,
  updatePath: missingProvider,
  prevId: undefined,
  alarms: null,
  relaxationOnly: null,
  facilitatorSession: false,
});

export const TherapySessionProvider: React.FC = ({ children }) => {
  const [therapySession, setTherapySession] = React.useState<ITherapySession>();
  const [hasAlarms, setHasAlarms] = useLocalStorage('hasAlarms', false);
  const [prevId, setPrevId] = React.useState<string>();

  React.useEffect(() => {
    async function createSession() {
      const res = await createTherapySession({ client_version: config.version });
      setTherapySession(res?.data?.data);
    }

    if (!therapySession?.id) {
      // Create new session if one doesn't exist
      createSession();
    }

    if (therapySession?.attributes.path.length) {
      updateTherapySession(therapySession);
    }
  }, [therapySession]);

  const updatePath = React.useCallback((nextId: string, response?: SpeechResponse, experienceLevel?: number) => {
    setTherapySession(ts => {
      if (!ts) {
        return ts;
      }

      const time = now();
      let { path } = ts.attributes;

      const next: IPathItem = {
        id: nextId,
        startAt: time,
        ...(experienceLevel > 0 && { experience: experienceLevel }),
      };

      const previous: IPathItem = path?.length > 0 && path[path.length - 1];

      setPrevId(previous?.id);

      if (previous) {
        previous.endAt = time;
        previous.response = response;

        path.pop();
        path = [...path, previous, next];
      } else {
        path = [...path, next];
      }

      // HACK: Unique'ify the path...
      // Some users are getting duplicate path entries which we can't recreate
      // so we'll force the `path` to be unique based on `startAt`
      const uniquePath = uniqueObjectArrayByKey(path, 'startAt');

      return {
        ...ts,
        attributes: { ...ts.attributes, path: uniquePath },
      };
    });
  }, []);

  const value: ITherapySessionContext = {
    therapySession,
    updatePath,
    prevId,
    alarms: {
      hasAlarms,
      setHasAlarms,
    },
    relaxationOnly: isRelaxationOnly(therapySession),
    facilitatorSession: isFacilitatorSession(therapySession),
  };

  return <TherapySessionContext.Provider value={value}>{children}</TherapySessionContext.Provider>;
};

export const useTherapySession = (): ITherapySessionContext => React.useContext(TherapySessionContext);
