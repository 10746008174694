import React from 'react';
import { HashRouter, MemoryRouter } from 'react-router-dom';

interface Props {
  hideUrl: boolean;
}

const Router: React.FC<Props> = ({ hideUrl, children }) => {
  return hideUrl ? <MemoryRouter>{children}</MemoryRouter> : <HashRouter>{children}</HashRouter>;
};

export default Router;
