import AppEnv from '../models/AppEnv.enum';
import { versionNumber } from '../utils/versionNumber';

const assetsRoot = process.env.REACT_APP_S3_BUCKET;

const config = {
  version: versionNumber,
  env: process.env.REACT_APP_ENV as AppEnv,
  ga: {
    id: `${process.env.REACT_APP_GA_ID}`,
  },
  api: {
    root: `${process.env.REACT_APP_API_URL}`,
    url: `${process.env.REACT_APP_API_URL}/api`,
    login: `${process.env.REACT_APP_API_URL}/login`,
    assetsRoot,
  },
  preSessionTests: {
    questionTimeout: process.env.REACT_APP_PRESESSION_TO ? Number(process.env.REACT_APP_PRESESSION_TO) : 1000 * 15,
  },
  relaxation: {
    timeout: process.env.REACT_APP_RELAXATION_TO ? Number(process.env.REACT_APP_RELAXATION_TO) : 1000 * 60 * 60,
    delay: 1000 * 5, // 5 sec
  },
  session: {
    startId: '1000',
    endId: '2007',
    graduallyEndId: '2000',
    questionTimeout: process.env.REACT_APP_SESSION_TO ? Number(process.env.REACT_APP_SESSION_TO) : 1000 * 60 * 2,
    maxQuestionRepeat: 4,
    prestartBuffer: 1000,
  },
  audio: {
    audioPlaybackTest: `${assetsRoot}/misc/audio_check.mp3`,
    audioInputTestYes: `${assetsRoot}/misc/ready_yes.mp3`,
    audioInputTestNo: `${assetsRoot}/misc/ready_say_no.mp3`,
    relaxationShortWithInvite: `${assetsRoot}/misc/5000.mp3`,
    relaxationMediumWithInvite: `${assetsRoot}/misc/5001.mp3`,
    relaxationLongWithInvite: `${assetsRoot}/misc/5002.mp3`,
    relaxationShort: `${assetsRoot}/misc/5003.mp3`,
    relaxationMedium: `${assetsRoot}/misc/5004.mp3`,
    relaxationLong: `${assetsRoot}/misc/5005.mp3`,
    relaxationNo: `${assetsRoot}/misc/5006.mp3`,
    sessionTerminatedSeekAssistance: `${assetsRoot}/misc/3001.mp3`,
    alarm: `./static/audio/alarm_01.mp3`,
    silence: `./static/audio/1-second-of-silence.mp3`,
    reorientation: `${assetsRoot}/misc/2007.mp3`,
    timeout01Intro: `${assetsRoot}/misc/timeout_01_intro.mp3`,
    timeout02Instructions: `${assetsRoot}/misc/timeout_02_instructions.mp3`,
    timeoutQ02: `${assetsRoot}/misc/timeout_q_02.mp3`,
    therapySessionIntro: `${assetsRoot}/misc/placeholder.mp3`,
  },
  reorientation: {
    attempt1Timeout: process.env.REACT_APP_REORIENTATION_TO_1
      ? Number(process.env.REACT_APP_REORIENTATION_TO_1)
      : 1000 * 60 * 10,
    attempt2Timeout: process.env.REACT_APP_REORIENTATION_TO_2
      ? Number(process.env.REACT_APP_REORIENTATION_TO_2)
      : 1000 * 60 * 10,
    attempt3Timeout: process.env.REACT_APP_REORIENTATION_TO_3
      ? Number(process.env.REACT_APP_REORIENTATION_TO_3)
      : 1000 * 60 * 5,
  },
};

export default config;
