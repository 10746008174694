import { AxiosResponse } from 'axios';
import DataType from '../../models/DataType.enum';
import IApiResponse from '../../models/IApiResponse';
import { ITherapySession } from '../../models/therapySession/ITherapySession';
import { ITherapySessionAttributes } from '../../models/therapySession/ITherapySessionAttributes';
import api from '../api';

type CreateTherapySessionOptions = {
  client_version: string;
};

async function createTherapySession(
  options: CreateTherapySessionOptions,
): Promise<AxiosResponse<IApiResponse<ITherapySession>>> {
  const attributes: Partial<ITherapySessionAttributes> = {
    client_version: options.client_version,
  };

  const response = await api.post<IApiResponse<ITherapySession>>(`/therapy-sessions`, {
    data: {
      type: DataType.TherapySessions,
      attributes,
    },
  });

  return response;
}

export default createTherapySession;
