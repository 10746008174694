import * as React from 'react';

const IconMediumTimer: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      style={{
        width: '100%',
        maxWidth: '100%',
      }}
      className="prefix__block prefix__fill-current"
    >
      Clock icon (medium time)
      <path
        fill="currentColor"
        d="M25 50.06a25 25 0 1125-25 25 25 0 01-25 25zM36.22 9A19.46 19.46 0 0025 5.49v19.59l11.25-16z"
      />
    </svg>
  );
};

export default IconMediumTimer;
