/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react';
import randomBetween from '../utils/randomBetween';
import shuffleArray from '../utils/shuffleArray';

interface Props {
  onCorrect: () => void;
  answer: number;
}

const ArithmeticQuestion: React.FC<Props> = ({ onCorrect, answer }) => {
  const [tryAgain, setTryAgain] = React.useState(false);
  const [addend1, setAddend1] = React.useState<number>(0);
  const [addend2, setAddend2] = React.useState<number>(0);
  const [values, setValues] = React.useState<number[]>([]);

  React.useEffect(() => {
    setAddend1(randomBetween(1, answer - 1));

    setValues(
      shuffleArray(
        Array.from(
          {
            length: 9,
          },
          (_, idx) => {
            if (idx > 0 && idx < 5) {
              return answer - idx;
            }

            if (idx >= 5) {
              return answer + idx;
            }

            return answer;
          },
        ),
      ),
    );
  }, [answer]);

  React.useEffect(() => {
    setAddend2(answer - addend1);
  }, [addend1, answer]);

  function checkAnswer(val: number): void {
    if (val === answer) {
      onCorrect();
      setTryAgain(false);
    } else {
      setTryAgain(true);
    }
  }

  return (
    <div className="well md:w-4/5">
      <h2 className="h2">
        What is {addend1} {addend2 < 0 ? '-' : '+'} {Math.abs(addend2)}?
      </h2>
      {tryAgain && <p className="text-red mb-4 -mt-4 italic font-semibold">Not quite… Please try again.</p>}
      <div className="grid items-stretch grid-cols-3 gap-6">
        {values.map(val => {
          return (
            <button
              key={val}
              type="button"
              className="sm:py-8 md:px-8 md:py-6 text-gray focus:bg-gold focus:text-white block px-0 py-4 text-3xl text-center no-underline bg-white"
              onClick={event => {
                (event.target as HTMLButtonElement).blur();
                checkAnswer(val);
              }}
            >
              {val}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ArithmeticQuestion;
