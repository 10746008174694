import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import ReactGA from 'react-ga';
import config from '../config/config';
import routes from '../config/routes';
import usePauseMenu from '../hooks/usePauseMenu';
import SpeechResponse from '../models/SpeechResponse';
import ActiveQuestion from './ActiveQuestion';

const RelaxationIntroNo: React.FC<RouteComponentProps> = ({ history }) => {
  React.useEffect(() => ReactGA.pageview(routes.RelaxationIntroNo), []);

  const { PauseMenu } = usePauseMenu({ showResume: true });
  const onAudioEnd = React.useCallback(() => {
    history.push(routes.SessionTerminated);
  }, [history]);

  const onAnswer = React.useCallback(
    res => {
      if (res === SpeechResponse.No) {
        history.push(routes.SessionIntro);
      }
    },
    [history],
  );

  return (
    <>
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Relaxation Introduction</h1>
      </header>

      <div className="justify-stretch align-stretch flex flex-col flex-1 text-center">
        <ActiveQuestion
          text="Please listen to the audio that is playing."
          audioSrc={config.audio.relaxationNo}
          repeat={0}
          timeout={config.session.questionTimeout}
          onAnswer={onAnswer}
          onAudioEnd={onAudioEnd}
          onTimeout={onAudioEnd}
          acceptYes={false}
          acceptNo
        />
      </div>

      <PauseMenu />
    </>
  );
};

export default RelaxationIntroNo;
