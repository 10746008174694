import * as React from 'react';

const IconPlay: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      style={{
        width: '100%',
        maxWidth: '100%',
      }}
    >
      Play
      <path fill="currentColor" d="M7.77 0v50l39.28-25z" />
    </svg>
  );
};

export default IconPlay;
