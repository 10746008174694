import { AxiosResponse } from 'axios';
import IApiResponse from '../../models/IApiResponse';
import IQuestion from '../../models/question/IQuestion';
import api from '../api';

async function getQuestion(id: string): Promise<AxiosResponse<IApiResponse<IQuestion>>> {
  const response = await api.get<IApiResponse<IQuestion>>(`/questions/${id}`);
  return response;
}

export default getQuestion;
