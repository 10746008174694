import React from 'react';
import { useHistory } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import AudioManager from '../lib/AudioManager';
import SpeechResponse from '../models/SpeechResponse';
import ActiveQuestion from './ActiveQuestion';

enum State {
  Initial,
  Alarm,
  WaitForYesToContinue,
  VerifyContinue,
  AskUM,
}

interface Props {
  continueSession: () => void;
}

const TimeoutOverlay: React.FC<Props> = ({ continueSession }) => {
  const history = useHistory();
  const { alarms } = useTherapySession();

  const [state, setState] = React.useState<State>(State.Initial);

  React.useEffect(() => {
    console.log(alarms);

    if (state === State.Alarm) {
      if (alarms?.hasAlarms) {
        AudioManager.createAudio({
          src: config.audio.alarm,
          onend: () => {
            setState(State.WaitForYesToContinue);
          },
        });
      } else {
        setState(State.WaitForYesToContinue);
      }
    }
  }, [state, alarms]);

  return (
    <div className="bg-tan-med scroll-y-auto justify-stretch absolute inset-0 flex flex-col flex-1 max-w-screen-md min-h-full mx-auto">
      <header className="pt-8 pb-2 text-center">
        <h1 className="h1 mx-auto mb-2">Timed Out</h1>
      </header>

      {state === State.Initial && (
        <ActiveQuestion
          audioSrc={config.audio.timeout01Intro}
          text="Is the unconscious mind still receiving this communication?"
          timeout={config.session.questionTimeout}
          repeat={0}
          onAnswer={(res: SpeechResponse) => res === SpeechResponse.Yes && continueSession()}
          onTimeout={() => setState(State.Alarm)}
          acceptYes
          acceptNo={false}
        />
      )}

      {state === State.Alarm && (
        <div className="flex flex-col items-center justify-center flex-grow p-4">
          <p className="my-6">Are you here?</p>
          <button
            type="button"
            className="button button--primary w-full"
            onClick={() => {
              AudioManager.pause();
              setState(State.WaitForYesToContinue);
            }}
          >
            Yes
          </button>
        </div>
      )}

      {state === State.WaitForYesToContinue && (
        <ActiveQuestion
          audioSrc={config.audio.timeout02Instructions}
          text="Please give a Yes signal to continue..."
          timeout={config.session.questionTimeout}
          repeat={0}
          onAnswer={(res: SpeechResponse) => {
            if (res === SpeechResponse.Yes) {
              setState(State.VerifyContinue);
            }
          }}
          onTimeout={() => history.push(routes.SessionTerminated)}
          acceptYes
          acceptNo={false}
        />
      )}

      {state === State.VerifyContinue && (
        <ActiveQuestion
          audioSrc={config.audio.timeoutQ02}
          text="Does the unconscious mind want to continue this updating session?"
          timeout={config.session.questionTimeout}
          repeat={0}
          onAnswer={(res: SpeechResponse) => {
            if (res === SpeechResponse.Yes) {
              continueSession();
            } else {
              setState(State.AskUM);
            }
          }}
          onTimeout={() => history.push(routes.SessionTerminated)}
        />
      )}

      {state === State.AskUM && (
        <ActiveQuestion
          audioSrc={config.audio.timeout01Intro}
          text=""
          timeout={config.session.questionTimeout}
          repeat={0}
          onAnswer={(res: SpeechResponse) => {
            if (res === SpeechResponse.Yes) {
              continueSession();
            } else {
              history.push(routes.SessionTerminated);
            }
          }}
          onTimeout={() => history.push(routes.SessionTerminated)}
        />
      )}
    </div>
  );
};

export default TimeoutOverlay;
