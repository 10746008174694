const routes = {
  Home: '/',
  Session: '/session/:questionId',
  SessionIntro: '/session-intro',
  TestAudioPlayback: '/test-audio-playback',
  Reorientation: '/reorientation',
  RelaxationIntro: '/relaxation-intro',
  RelaxationIntroNo: '/relaxation-intro-no',
  SessionTerminated: '/session-terminated',
  SessionGuide: '/session-guide',
  Meditation: '/meditation',
};

export default routes;
