import { AxiosResponse } from 'axios';
import IApiResponse from '../../models/IApiResponse';
import { ITherapySession } from '../../models/therapySession/ITherapySession';
import api from '../api';

async function updateTherapySession(data: ITherapySession): Promise<AxiosResponse<IApiResponse<ITherapySession>>> {
  const response = await api.patch<IApiResponse<ITherapySession>>(`/therapy-sessions/${data.id}`, { data });
  return response;
}

export default updateTherapySession;
