import React from 'react';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import AppEnv from '../models/AppEnv.enum';

const SessionGuide: React.FC = () => {
  const debug = config.env === AppEnv.Dev;
  React.useEffect(() => ReactGA.pageview(routes.SessionGuide), []);

  return (
    <>
      <h1 className="h1 pt-8 mx-auto mb-2 text-center">Session Guide</h1>
      <main className="justify-stretch align-stretch flex flex-col flex-1 p-4">
        <div className="">
          <section className="px-6 py-4 space-y-4">
            <p>
              During your Emotional Updating™ session you will be frequently asked to respond with a &ldquo;Yes&rdquo;
              or &ldquo;No&rdquo; signal.
            </p>
            <p>
              You may tap or click the &ldquo;Yes&rdquo; and &ldquo;No&rdquo; buttons that will appear on your screen or
              you may use the &ldquo;Right Arrow&rdquo; and &ldquo;Left Arrow&rdquo; keys on your keyboard to answer
              &ldquo;Yes&rdquo; or &ldquo;No&rdquo; respectively.
            </p>
            <p>Please disable any display auto-lock or screen timeout settings.</p>
            <p>Please disable notifications and enable &ldquo;Do Not Disturb&rdquo; mode on your device.</p>
          </section>
          <p className="my-6 text-center">
            <Link className="button button--primary mx-auto mb-4 text-2xl font-semibold" to={routes.TestAudioPlayback}>
              Continue
            </Link>
            {debug && (
              <Link
                className="button button--primary mx-auto mb-4 text-2xl font-semibold"
                to={`/session/${config.session.startId}`}
              >
                Debug: Skip to session
              </Link>
            )}
          </p>
        </div>
      </main>
    </>
  );
};

export default SessionGuide;
