import { AxiosResponse } from 'axios';
import { RelaxationType } from '../../models/RelaxationType.enum';
import api from '../api';

async function createTherapySessionRelaxation(therapySession: string, type: RelaxationType): Promise<AxiosResponse> {
  const response = await api.post(`/therapy-sessions/${therapySession}/relationships/relaxations`, {
    data: {
      id: 'relaxations',
      attributes: {
        type,
      },
    },
  });
  return response;
}

export default createTherapySessionRelaxation;
