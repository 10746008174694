/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
function shuffleArray<T>(arr: Array<T>): Array<T> {
  const array = [...arr];

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export default shuffleArray;
