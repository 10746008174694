import React from 'react';
import ReactGA from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';
import config from '../config/config';
import routes from '../config/routes';
import { useTherapySession } from '../contexts/TherapySessionContext';
import AudioManager from '../lib/AudioManager';

const SessionTerminatedSeekAssistance: React.FC<RouteComponentProps> = ({ history }) => {
  React.useEffect(() => ReactGA.pageview(routes.SessionTerminated), []);

  const { updatePath } = useTherapySession();

  React.useEffect(() => {
    updatePath('3001', undefined);
  }, [updatePath]);

  React.useEffect(() => {
    AudioManager.createAudio({
      src: config.audio.sessionTerminatedSeekAssistance,
      onend: () => {
        history.push(routes.Reorientation);
      },
    });
  }, [history]);

  return (
    <>
      <h1 className="h1 pt-16 mx-auto mb-2 text-center">Session Terminated</h1>
      <main className="justify-stretch align-stretch flex flex-col flex-1 p-4 text-center">
        <p>
          Since a signal was not received the unconscious mind will need additional input from Dr. Hercules to further
          address this. You will be contacted by him as soon as possible to arrange this. Please feel free to contact
          him as well. I would like to thank the unconscious mind for its cooperation today. When you are ready, taking
          the time required, please open your eyes and take whatever time you need to fully reorient yourself to your
          surroundings before engaging in any other activities.
        </p>
      </main>
    </>
  );
};

export default SessionTerminatedSeekAssistance;
