import axios, { AxiosError } from 'axios';
import config from '../../config/config';

const api = axios.create({
  baseURL: config.api.url,
  withCredentials: true,
});

api.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401 || error.response?.status === 419) {
    window.location.replace(`${config.api.login}?url=${encodeURIComponent(window.location.href)}`);
  }
});

export default api;
