import * as React from 'react';

const IconRestart: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      style={{
        width: '100%',
        maxWidth: '100%',
      }}
    >
      Restart icon
      <path
        fill="currentColor"
        d="M25.8 11.7V.9L11.4 15.3l14.4 14.4V19.2c5.4.8 9.5 5.4 9.5 11 0 6.1-5 11.1-11.1 11.1s-11.1-5-11.1-11.1v-1.6H5.7v1.6c0 10.2 8.3 18.5 18.5 18.5s18.5-8.3 18.5-18.5c0-9.7-7.4-17.7-16.9-18.5z"
      />
    </svg>
  );
};

export default IconRestart;
